<template>
  <div class="about">
    <h1>This is an about page</h1>
  </div>
</template>

<script>
export default {
  name: "AboutView",
  data() {
    return {
      isLoading: false
    };
  },
  mounted: function () {
    this.$store.state.isPageLoading = false;
  }
};
</script>
